import Layout from "../components/layout"
import Seo from "../components/seo"
import ArticleCard from "../components/article-card"
import { useLocation } from "@reach/router"
import SiteImage from "../../static/logo.png"

type Props = {
  pageContext: {
    name: string
    contents: GatsbyTypes.ContentfulArticles[]
  }
}

export default function ArticleListByTag({ pageContext }: Props) {
  const { name, contents } = pageContext
  const title = `${name}の記事一覧`
  const description = `${title}の記事一覧ページです。`

  const location = useLocation()
  const siteImageUrl = `${process.env.GATSBY_SITE_ORIGIN}${SiteImage}`

  return (
    <Layout pageType="">
      <Seo title={title} url={location.href} image={siteImageUrl} description={description} />
      <main className="pt-12 sm:pt-0 mx-auto lg:max-w-screen-lg md:max-w-screen-md sm:max-w-screen-sm">
        <div className="border-b text-3xl text-gray-800 font-bold py-10 px-4 mb-8">
          # {name}
        </div>
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {contents.map(content => {
            return (
              <ArticleCard
                key={content.id}
                articleId={content.id}
                titleForCard={content.titleForCard}
                createdAt={content.createdAt}
                thumbnailUrl={content.thumbnail?.file?.url}
                tags={content.metadata?.tags}
              />
            )
          }
          )}
        </div>
      </main>
    </Layout>
  )
}
